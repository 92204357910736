const config = {
    rollbar: {
      accessToken: "8dc3a47a65c5499b9bc9b93aaf9fc372",
      env: "prod"
    },
    captcha: {
      sitekey: "6LfJ4jwbAAAAABkOxONVVddw81bLQrqo5JCiBC5_"
    }
  }

export default config
